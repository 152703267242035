import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Item } from './item.model';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss']
})
export class ItemComponent implements OnInit {
  id: string;
  item: Observable<Item>;
  constructor(private route: ActivatedRoute, private afs: AngularFirestore) {
    
  }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    this.item = this.afs.doc<Item>('items/'+this.id).valueChanges();
    // this.item = this.route.paramMap.pipe(
    //     switchMap((params: ParamMap, index: number) => {
    //         this.id = params.get('id');
    //         return this.afs.doc<Item>('items/'+this.id).valueChanges();
    //     })
    // );
    this.item.subscribe( item => console.log(item))
  }

}
