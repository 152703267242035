import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, from } from 'rxjs';
import { Item } from '../item/item.model';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-item-list',
  templateUrl: './item-list.component.html',
  styleUrls: ['./item-list.component.css']
})
export class ItemListComponent {

    items: Observable<Item[]>;
    constructor(db: AngularFirestore) {
        this.items = db.collection<Item>('items').snapshotChanges().pipe(map( changes => {
            return changes.map( change => {
                const data = change.payload.doc.data() as Item
                const itemId = change.payload.doc.id;
                data.id = itemId
                return data;
            })
        }))
      }

}
