import { AppComponent } from "./app.component";
import { ItemComponent } from "./item/item.component";
import { Routes } from "@angular/router";
import { ItemListComponent } from "./item-list/item-list.component";

export const appRoutes: Routes = [
    { path: 'items/:id', component: ItemComponent },
    { path: 'items', component: ItemListComponent },
    { path: '',
      redirectTo: '/items',
      pathMatch: 'full'
    }
  ];